.tabs-container .tab-pane .panel-body {
    border-top: none;
    border-bottom: 1px solid #e7eaec !important;
  }

/* The sidebar show */
.sidebar {
	height: 100%; 
	width: 0; /* 0 width - change this with JavaScript */
	position: fixed; /* Stay in place */
	z-index: 1; /*   Stay on top */
	/* top: 0; */
	right: 0;
	background-color: #fff !important;
	overflow-x: hidden;
	overflow-y: scroll; 
	transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
	border-left : 2px solid #F3F3F4;
  }
  
  /* Position and style the close button (top right corner) */
  .sidebar .closebtn {
	position: absolute;
	top: 0;
	right: 25px;
	font-size: 36px;
	margin-left: 50px;
  }
  
  /* Style page content - use this if you want to push the page content to the right when you open the side navigation */
  #main {
	height: 100%; 
	transition: margin-left .5s;
	overflow-y: auto;
	overflow-x: hidden;
  
  }
  
  
.ibox-content > .sk-spinner {
	display: none;
  }
  .ibox-content.sk-loading {
	position: relative;
  }
  .ibox-content.sk-loading:after {
	content: '';
	background-color: rgba(255, 255, 255, 0.7);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
  }
  .ibox-content.sk-loading > .sk-spinner {
	display: block;
	position: absolute;
	top: 40%;
	left: 0;
	right: 0;
	z-index: 2000;
  }
  
  
  
  
  table.dataTable > tbody > tr.selected > *, table.dataTable.table-hover > tbody > tr.selected:hover > * {
	box-shadow: inset 0 0 0 9999px rgba(218, 218, 218, .25);
	color: black;
  }
  
  table.dataTable > tbody > tr.selected a {
	color:#000;
  }
  
  
  table.dataTable thead > tr > th.sorting:nth-child(1)::before, table.dataTable thead > tr > th.sorting:nth-child(1)::after {
	content: "";
	bottom: 50%;
	top: 50%;
  }
  
  table.dataTable thead > tr > th.sorting_asc:nth-child(1)::before, table.dataTable thead > tr > th.sorting_asc:nth-child(1)::after {
	content: "";
	bottom: 50%;
	top: 50%;
  }
  
  
  table.dataTable thead > tr > th.sorting_desc:nth-child(1)::before, table.dataTable thead > tr > th.sorting_desc:nth-child(1)::after {
	content: "";
	bottom: 50%;
	top: 50%;
  }
  
  /* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
  @media screen and (max-height: 450px) {
	.sidebar {padding-top: 15px;}
  
  }
  
  
  
  .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
	  width: 100%;
	  padding-right: 0px;
	  padding-left: 0px;
	  margin-right: auto;
	  margin-left: auto;
  }

.navbar-brand {
	font-size: 1.4em;
  }
  .navbar-dark .navbar-nav a.nav-link {
	color: #ffffff;
	font-size: 1.1em;
  }
  

  @media only screen and (min-width: 992px) {
	.dropdown:hover .dropdown-menu {
	  display: flex;
	}
	.dropdown-menu.show {
	  display: flex;
	}
  }

  .dropdown-menu ul {
	list-style: none;
	padding: 0;
  }
  .dropdown-menu li .dropdown-item {
	color: gray;
	font-size: 1em;
	padding: 0.5em 1em;
  }
  .dropdown-menu li .dropdown-item:hover {
	background-color: #f1f1f1;
  }

  .dropdown-menu li:first-child a:hover {
	background-color: #f1f1f1;
  }
  @media only screen and (max-width: 992px) {
	.dropdown-menu.show {
	  flex-wrap: wrap;
	  max-height: 350px;
	  overflow-y: scroll;
	}
  }
  @media only screen and (min-width: 992px) and (max-width: 1140px) {
	.dropdown:hover .dropdown-menu {
	  width: 40vw;
	  flex-wrap: wrap;
	}
  }

  .dropdown-menu ul {
	list-style: none;
	padding: 0;
  }
  
  .dropdown-menu li a {
	color: gray;
	padding: 0.5em 1em;
  }
  
  @media screen and (min-width: 993px) {
	.dropdown:hover .dropdown-menu {
	  display: flex;
	}
	.dropdown-menu.show {
	  display: flex;
	}
  }
  @media screen and (max-width: 993px) {
	.dropdown-menu.show {
	  max-height: 60vh;
	  overflow-y: scroll;
	}
  }
  

.change-message {
	display: none;
  }
  

  
  thead input {
	background-color: #fbfbfb !important;
  }
  
  thead input:focus {
	background-color: #fff !important;
  }
  
  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
	position: absolute;
	left: -9999px;
  }
  
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
	position: relative;
	padding-left: 28px;
	cursor: pointer;
	line-height: 20px;
	display: inline-block;
	color: #666;
  }
  
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 18px;
	height: 18px;
	border: 1px solid #ddd;
	border-radius: 100%;
	background: #fff;
  }
  
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
	content: '';
	width: 12px;
	height: 12px;
	background: #1ab394;
	position: absolute;
	top: 3px;
	left: 3px;
	border-radius: 100%;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
  }
  
  [type="radio"]:not(:checked) + label:after {
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
  }
  
  [type="radio"]:checked + label:after {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
  }
  

  
  @media (min-device-width: 320px) and (max-device-width: 700px) {
	.timeline {
	  list-style-type: none;
	  display: block;
	}
	.li {
	  transition: all 200ms ease-in;
	  display: flex;
	  width: inherit;
	}
	.timestamp {
	  width: 100px;
	}
	.status:before {
	  left: -8%;
	  top: 30%;
	  transition: all 200ms ease-in;
	}
  }
  
  /* Mise en page */
  html, body {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	font-family: 'Titillium Web', sans-serif;
	color: #758D96;
  }
  
  /* button {
	position: absolute;
	width: 100px;
	min-width: 100px;
	padding: 20px;
	margin: 20px;
	font-family: 'Titillium Web', sans-serif;
	border: none;
	color: white;
	font-size: 16px;
	text-align: center;
  } */
  
  /* #toggleButton {
	position: absolute;
	left: 50px;
	top: 20px;
	background-color: #75C7F6;
  } */

/* Conteneur principal pour l'alignement */
.search-container {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Permet de bien séparer les blocs si nécessaire */
    gap: 10px; /* Espacement horizontal */
}

/* Alignement du wrapper de recherche */
.search-wrapper {
    display: flex;
    align-items: center;
    gap: 5px; /* Espacement entre le SVG et le texte */
}

/* Style du SVG (icone de recherche) */
.search-icon {
    display: inline-block;
    vertical-align: middle;
}

/* Texte de la vue des lignes */
.search-text {
    font-size: 18px; /* Ajuste la taille pour qu'elle corresponde à l'input */
    line-height: 30px; /* Alignement parfait avec l'icone */
    margin-left: 5px;
}

/* Style pour l'input de recherche */
.search-input {
    flex-grow: 1; /* Prend tout l'espace disponible */
    height: 38px; /* Hauteur standard Bootstrap */
    font-size: 13px;
    line-height: 1.5; /* Assure un alignement interne propre */
}

/* Boutons d'action à gauche */
.tablelist .btn {
    height: 38px; /* Même hauteur que l'input */
    padding: 0.375rem 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Espacement des icones dans les boutons */
.tablelist .btn i {
    margin: 0;
    vertical-align: middle;
    font-size: 18px;
}

/* Styles responsives */
@media (max-width: 768px) {
    .search-container {
        flex-wrap: wrap; /* Passe en ligne verticale sur petits écrans */
    }

    .search-input {
        width: 100%; /* Input en pleine largeur */
        margin-top: 8px;
    }
}


/* Conteneur principal */
.dropdowntable {
    position: relative;
    display: inline-block;
    margin-right: 1px;
    margin-bottom: 4px;
}

/* Bouton dropdown */
.dropdowntable .dropdown-toggle {
    background: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 6px 8px;
    color: #333;
    cursor: pointer;
    display: flex;
    align-items: center;
}

/* Supprimer le caret */
.dropdowntable .dropdown-toggle::after {
    display: none !important;
}

/* Menu Dropstart */
.dropdowntable.dropstart .dropdown-menu {
    position: absolute;
    top: 0%; 
    right: 100%; /* Aligne le menu à gauche du bouton */
    transform: translateY(-50%); /* Centrage vertical */
    z-index: 1050;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    min-width: 350px;
    padding: 0;
    display: none; /* Masqué par défaut */
}

/* Affichage du menu au survol */
.dropdowntable:hover .dropdown-menu {
    display: block !important; /* Affiche le menu */
}

/* Ajustement des marges et padding */
.dropdowntable table {
    border-collapse: collapse;
    width: 100%;
    font-size: 12px;
    margin: 0;
    padding: 0;
}

 .badge {
  display: inline-block;
  font-size: 11px;
  font-weight: 600;
  padding: 3px 6px;
  border: 1px solid transparent;
  min-width: 10px;
  line-height: 1;
  margin-right: 2px;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 3px
}

.chat-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	border: 1px solid #ddd;
}
.message {
	padding: 10px;
	margin-bottom: 10px;
	border-radius: 8px;
	max-width: 80%;
	word-wrap: break-word;
}
.textarea-ai {
	margin-bottom: 0px !important;
}
.user-message {
	background-color: #d1ecf1;
	align-self: flex-end;
}
.bot-message {
	background-color: #f8f9fa;
	align-self: flex-start;
}
table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}
th, td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
}
th {
    background-color: #007bff;
    color: white;
}

a {
	color: #007bff;
	text-decoration: none;
}
a:hover {
	text-decoration: underline;
}

.progress {
    width: 100%;
    margin-bottom: 10px;
}

/* Barre de chargement */
#loading-bar {
    display: none;
    width: 100%;
    height: 5px;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 10px;
}

#loading-progress {
    width: 0%;
    height: 100%;
    background-color: #007bff;
    transition: width 0.4s ease-in-out;
}

